import React from 'react'
import { Marker } from '@react-google-maps/api'


const MarkerComponent = (props) => {

    const marker = props.marker;

    const handlePinImgPath = (ratio, isLargePassageArea, isWA, isOpen) => {

        let pourcent = 0;

        if (ratio === 0)
            pourcent = 0;
        else if (ratio <= 10)
            pourcent = 10;
        else if (ratio <= 20)
            pourcent = 20;
        else if (ratio <= 30)
            pourcent = 30;
        else if (ratio <= 40)
            pourcent = 40;
        else if (ratio <= 50)
            pourcent = 50;
        else if (ratio <= 60)
            pourcent = 60;
        else if (ratio <= 70)
            pourcent = 70;
        else if (ratio <= 80)
            pourcent = 80;
        else if (ratio <= 90)
            pourcent = 90;
        else if (ratio <= 100)
            pourcent = 100;
        else
            pourcent = 0;

        if (isLargePassageArea) {
            if (ratio === 0)
                return "/google-pins/marqueur_gp_tout-libre_etat-normal@2x.png";
            return `/google-pins/marqueur_gp_occupation-${pourcent}pct_etat-normal@2x.png`;
        }
        else if (!isWA){
            if (ratio === 0 && isOpen === 0)
                return "/google-pins/marqueur_non_aire_tout-libre_etat-normal@2x.png";
            if (isOpen === 1) 
                return "/google-pins/marqueur_non_aire_tout-libre_ferme_etat-normal@2x.png";   
            
            return `/google-pins/marqueur_non_aire_occupation-${pourcent}pct_etat-normal@2x.png`
        }
        else {
            if (ratio === 0 && isOpen === 0)
                return "/google-pins/marqueur_aire_tout-libre_etat-normal@2x.png";
            if (isOpen === 1) 
                return "/google-pins/marqueur_aire_tout-libre_etat-ferme-normal@2x.png"; 
            return `/google-pins/marqueur_aire_occupation-${pourcent}pct_etat-normal@2x.png`
        }
    }
    return (
        <Marker
            position={{ lat: marker.latitude, lng: marker.longitude }}
            
            icon={{
                url: handlePinImgPath((marker.busySlots / marker.totalSlots) * 100, marker.isLargePassageArea, marker.isWa, marker.isOpen),
                scaledSize: new window.google.maps.Size(40, 59),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 30)
            }}
            animation={2}
            onClick={props.onClick}
        />
    )
}

export default MarkerComponent
