import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import Loader from 'react-loader-spinner';
import { fetchMarkers } from '../redux/actions/actionFetchMarkers';
import InfoWindowComponent from '../components/InfoWindowComponent';
import MarkerComponent from '../components/MarkerComponent';
import mapStyles from '../mapStyle';

const mapContainerStyle = {
    width: "100vw",
    height: "100vh"
}

const center = {
    lat: 47.227638,
    lng: 2.213749
}

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true
}

const Map = () => {

    const state = useSelector(state => state.markers)
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(null)
    const mapRef = useRef([])

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, [])

    useEffect(() => {
        dispatch(fetchMarkers());

    }, [dispatch])

    if (loadError)
        return "Error loading map"
    if (!isLoaded)
        return "Map is loading..."
    return (
        <GoogleMap
            zoom={6}
            center={center}
            mapContainerStyle={mapContainerStyle}
            options={options}
            onLoad={onMapLoad}>
            {state.isLoading ? (<Loader type="Bars" color="#FFF" className="Loader"/>)
                : state.error !== '' ? (<p>{console.log(state.error)}</p>)
                : state.fetchMarkers.map(marker => <MarkerComponent marker={marker} onClick={() => { setSelected(marker) }} key={marker.name} />)}

            {selected ? (<InfoWindowComponent selected={selected} onCloseClick={() => { setSelected(null) }} />) : null}

        </GoogleMap>
    )
}

export default Map
