import {FETCH_MARKERS_LOADING, FETCH_MARKERS_SUCCESS, FETCH_MARKERS_ERROR} from '../constants'

const initialState = {
    isLoading: false,
    fetchMarkers: [],
    error: ''
}

function reducerFetchMarkers(state = initialState, action) {
   switch (action.type) {
       case FETCH_MARKERS_LOADING:
           return {
               ...state,
               isLoading: true,
               fetchMarkers: [],
               error: ''
           }

        case FETCH_MARKERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fetchMarkers: action.payload,
                error: ''
            }
        case FETCH_MARKERS_ERROR:
            return {
                ...state,
                isLoading: false,
                fetchMarkers: [],
                error: action.payload
            }
   
       default:
           return state;
   }
}

export default reducerFetchMarkers
