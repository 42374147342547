import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'

const Header = () => {
    return (
        <div id="block_container" className="header-container">
            <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>
    )
}

export default Header
