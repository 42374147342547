import Map from './containers/Map';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header';
import './App.css'
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Map} />
        <Route component={Map} />
      </Switch>
    </Router>
  );
}

export default App;
