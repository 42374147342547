import {createStore, combineReducers, applyMiddleware} from 'redux'
import reducerFetchMarkers from './reducers/reducerFetchMarkers'
import thunk from 'redux-thunk'

const rootreducer = combineReducers({
    markers: reducerFetchMarkers
})

const store = createStore(rootreducer, applyMiddleware(thunk))

export default store