import {FETCH_MARKERS_LOADING, FETCH_MARKERS_SUCCESS, FETCH_MARKERS_ERROR} from '../constants'
import axios from 'axios'

const fetchMarkersLoading = () => {
    return {
        type: FETCH_MARKERS_LOADING
    }
}

const fetchMarkersSuccess = data => {
    return {
        type: FETCH_MARKERS_SUCCESS,
        payload: data
    }
}

const fetchMarkersError = error => {
    return {
        type: FETCH_MARKERS_ERROR,
        payload: error
    }
}

export const fetchMarkers = () => {

    axios.defaults.headers.get['ApiKey'] = process.env.REACT_APP_WA3_API_KEY;
    
    return disatch => {
        disatch(fetchMarkersLoading())

        axios.get(`${process.env.REACT_APP_API}Markers/GetMarkers`)
        .then(res => {
            const markersArray = res.data.markers;
            disatch(fetchMarkersSuccess(markersArray))
        })
        .catch(error => {
            disatch(fetchMarkersError(error.message))
        })
    }
}