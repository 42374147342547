import React from 'react'
import { InfoWindow } from '@react-google-maps/api'
import Moment from 'moment';

const InfoWindowComponent = (props) => {

    const selected = props.selected;
    const email = selected.contact.email;
    const phone = selected.contact.phone;
    const astreintePhone = selected.contact.astreintePhone;

    const displayEmail = email &&
        <div>
            <span>Email:
                <strong>
                    &nbsp; {email}
                </strong>
            </span>
            <br />
        </div>;

    const displayPhone = phone &&
        <div>
            <span>Téléphone:
                <strong>
                    &nbsp; {phone}
                </strong>
            </span>
            <br />
        </div>;

    const displayAstreintePhone = astreintePhone &&
        <div>
            <span>Astreinte:
                <strong>
                    &nbsp; {astreintePhone}
                </strong>
            </span>
            <br />
        </div>;

if (selected.isOpen === 1) {
    if (selected.allowGestInfo) {
        return (
            <InfoWindow
                position={{ lat: selected.latitude, lng: selected.longitude }}
                onCloseClick={props.onCloseClick}>
                <div>
                    <strong>{selected.name}</strong><br /><br />
                    <span>
                        Fermée
                    </span>
                    {displayEmail}
                    {displayPhone}
                    {displayAstreintePhone}
                </div>
            </InfoWindow>
        )   
    }else{
        return (
            <InfoWindow
                position={{ lat: selected.latitude, lng: selected.longitude }}
                onCloseClick={props.onCloseClick}>
                <div>
                    <strong>{selected.name}</strong><br /><br />
                    <span>
                        Fermée
                    </span>
                </div>
            </InfoWindow>
        )   
    }
         
}else{
    if (selected.isWa && selected.name === "Sainte Savine") {
        return (
            <InfoWindow
                position={{ lat: selected.latitude, lng: selected.longitude }}
                onCloseClick={props.onCloseClick}>
                <div>
                    <strong>{selected.name}</strong><br /><br />
                    <span>
                        Taux d'occupation:
                                <strong>
                            &nbsp; {(selected.busySlots / selected.totalSlots * 100).toFixed(0)}%
                                </strong>
                    </span>
                    <br />
                    <span>Emplacements disponibles:
                                <strong>
                            &nbsp; {selected.totalSlots - selected.busySlots}/{selected.totalSlots}
                        </strong>
                        &nbsp; <i>(Sous réserve de confirmation)</i>
                    </span>
                    <br />
                    {displayEmail}
                    {displayPhone}
                    {displayAstreintePhone}
                    <br />
                    <span style={{color: '#053383'}}>
                    
                    Date:
                    <b>        
                            &nbsp; {Moment().format('YYYY-MM-DD HH:mm')}
                        </b>
                    </span>
                </div>
                
            </InfoWindow>
        )
    }
    if (selected.isWa) {
        return (
            <InfoWindow
                position={{ lat: selected.latitude, lng: selected.longitude }}
                onCloseClick={props.onCloseClick}>
                <div>
                    <strong>{selected.name}</strong><br /><br />
                    <span>
                        Taux d'occupation:
                                <strong>
                            &nbsp; {(selected.busySlots / selected.totalSlots * 100).toFixed(0)}%
                                </strong>
                    </span>
                    <br />
                    <span>Emplacements disponibles:
                                <strong>
                            &nbsp; {selected.totalSlots - selected.busySlots}/{selected.totalSlots}
                        </strong>
                    </span>
                    <br />
                    {displayEmail}
                    {displayPhone}
                    {displayAstreintePhone}
                    <br />
                    <span style={{color: '#053383'}}>
                    
                    Date:
                    <b>        
                            &nbsp; {Moment().format('YYYY-MM-DD HH:mm')}
                        </b>
                    </span>
                </div>
                
            </InfoWindow>
        )
    }else{
        return (
            <InfoWindow
                position={{ lat: selected.latitude, lng: selected.longitude }}
                onCloseClick={props.onCloseClick}>
                <div>
                    <strong>{selected.name}</strong><br /><br />
                    <span>
                        Taux d'occupation:
                                <strong>
                            &nbsp; {(selected.busySlots / selected.totalSlots * 100).toFixed(0)}%
                                </strong>
                    </span>
                    <br />
                    <span>Emplacements disponibles:
                                <strong>
                            &nbsp; {selected.totalSlots - selected.busySlots}/{selected.totalSlots}
                        </strong>
                    </span>
                    <br />
                    {displayEmail}
                    {displayPhone}
                    {displayAstreintePhone}
                    <br />
                    <span style={{color: '#053383'}}>
                    
                    Date:
                    <b>       
                            &nbsp; {Moment(selected.lastUpdate).format('YYYY-MM-DD HH:mm')}
                        </b>
                    </span>
                </div>
                
            </InfoWindow>
        )
    }
    
    }
}

export default InfoWindowComponent
